import { useMemo } from "react";

import T from "@hpo/client/components/text/Text";
import { InstructorPaymentAmounts } from "@hpo/client/models/InstructorPayment";
import InfoBox from "@hpo/client/utilities/InfoBox";
import Intersperse from "@hpo/client/components/Intersperse";
import Spacer from "@hpo/client/utilities/Spacer";

export default function PaymentWarnings(props: {
  payment: InstructorPaymentAmounts;
}) {
  const warnings = useMemo(
    () => getPaymentWarnings(props.payment),
    [props.payment],
  );
  return (
    <Intersperse between={() => <Spacer scale={0.5} />} append prepend>
      {warnings.map((w) => (
        <InfoBox title={w.title} key={w.title} style="warn">
          <T>{w.detail}</T>
        </InfoBox>
      ))}
    </Intersperse>
  );
}

export function getPaymentWarnings(
  payment: InstructorPaymentAmounts,
): Array<{ title: string; detail: string }> {
  const warnings: Array<{ title: string; detail: string }> = [];

  if (payment.grantedAmount !== payment.theoreticalAmount) {
    warnings.push({
      title: "Montant accordé modifié",
      detail:
        "Le montant accordé est différent du montant défini dans le cadre de la convention initiale. Cela peut venir d'un avenant à la convention.",
    });
  }

  if (payment.stockAmount !== null && payment.stockAmount > 0) {
    warnings.push({
      title: "Report",
      detail:
        "Le montant que le bénéficiaire peut demander inclut un report. Ce report provient d'un paiement précédent qui n'a pas été entièrement acordé.",
    });
  }

  if (
    payment.validatedAmount !== null &&
    payment.instructedAmount !== null &&
    payment.validatedAmount < payment.instructedAmount
  ) {
    warnings.push({
      title: "Montant réduit (niv. 1)",
      detail:
        "Lors de la validation de niveau 1, le Département a réduit le montant de ce paiement",
    });
  }

  if (
    payment.certifiedAmount !== null &&
    payment.validatedAmount !== null &&
    payment.certifiedAmount < payment.validatedAmount
  ) {
    warnings.push({
      title: "Montant réduit (niv. 2)",
      detail:
        "Lors de la validation de niveau 2, le Département a réduit le montant de ce paiement",
    });
  }

  if (payment.maximalAdvance !== null) {
    warnings.push({
      title: "Avance",
      detail: "Ce paiement a fait l'objet d'une avance",
    });
  }

  return warnings;
}
