import { Fragment, useMemo } from "react";
import dayjs from "dayjs";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import { useMe } from "../../RootNavigator/services/WhoAmI";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import PaymentWarnings, { getPaymentWarnings } from "./PaymentWarnings";
import PaymentTable from "./PaymentTable";
import PaymentReceipts from "./PaymentReceipts";
import PaymentValidation from "./PaymentValidation";
import PaymentSettings from "./PaymentSettings";
import PaymentReportDisplay from "./PaymentReportDisplay";
import { InstructorPaymentDetails } from "@hpo/client/models/InstructorPayment";
import Await from "@hpo/client/utilities/Await";
import { conventionPaymentRoute } from "@hpo/client/utilities/routes";
import useData from "@hpo/client/utilities/useData";
import { useRouteParam } from "@hpo/client/utilities/Navigation";
import { getPaymentLabel } from "@hpo/client/models/BeneficiaryPayment";
import Spacer from "@hpo/client/utilities/Spacer";
import Intersperse from "@hpo/client/components/Intersperse";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import InstructorPaymentStatusBadge from "@hpo/client/utilities/InstructorPaymentStatusBadge";
import Theme from "@hpo/client/utilities/Theme";
import T from "@hpo/client/components/text/Text";
import { ConventionDetails } from "@hpo/client/models/Convention";
import Tabs from "@hpo/client/utilities/Tabs";
import InfoBox from "@hpo/client/utilities/InfoBox";

export default function ConventionPaymentScreen() {
  const server = useServerSdk();

  const conventionId = useRouteParam(conventionPaymentRoute, "conventionId");
  const paymentId = useRouteParam(conventionPaymentRoute, "paymentId");

  const [data, fetch] = useData(() => {
    return Promise.all([
      server.getPayment(paymentId),
      server.getConvention(conventionId),
    ]);
  }, [conventionId, paymentId]);

  return (
    <Await promise={data} hideReload>
      {([payment, convention]) => {
        return (
          <PaymentView
            payment={payment}
            convention={convention}
            onRefresh={fetch.reloadSilent}
          />
        );
      }}
    </Await>
  );
}

export type PaymentViewProps = {
  payment: InstructorPaymentDetails;
  convention: ConventionDetails;
  onRefresh: () => unknown;
};

function PaymentView(props: PaymentViewProps) {
  const { payment, convention } = props;
  const me = useMe();

  const warnings = useMemo(() => getPaymentWarnings(payment), [payment]);

  const assistance = useAssistance();
  assistance.useChatWith(convention.organizations);

  return (
    <Page>
      <WithHelp
        inactive={warnings.length === 0}
        title="Points de vigilance"
        text={<PaymentWarnings payment={payment} />}
        color={Theme.warning}
      >
        <PageHeader
          title={getPaymentLabel(payment)}
          right={<InstructorPaymentStatusBadge status={payment.status} />}
          subtitle={
            payment.committeeDate
              ? `Comité technique de suivi ${dayjs(payment.committeeDate).fromNow()} (${dayjs(payment.committeeDate).format("LL")})`
              : "Date du comité technique inconnue"
          }
        />
      </WithHelp>
      {payment.cancelReason ? (
        <Fragment>
          <Spacer />
          <InfoBox title="Instruction" style="warn">
            <T>
              L'instruction a été refusée pour la raison suivante :<br />
              {payment.cancelReason}
            </T>
          </InfoBox>
        </Fragment>
      ) : null}
      <Intersperse between={() => <Spacer />} append prepend>
        <PaymentReceipts {...props} />
        <Fragment>
          <T style="subtitle">Montants</T>
          <PaymentTable payment={payment} />
        </Fragment>
        <Fragment>
          <T style="subtitle">Rapport d'instruction</T>
          <PaymentReportDisplay {...props} />
        </Fragment>
        <Fragment>
          <T style="subtitle">Signature</T>
          <Spacer />
          <Tabs>
            {me.roles.includes("instructor") ? (
              <Tabs.Tab id="instructor" title="Par l'instructeur">
                <PaymentValidation
                  {...props}
                  type="instructor"
                  amountProp={
                    payment.status === "under-instruction"
                      ? "requestedAmount"
                      : "instructedAmount"
                  }
                  notesProps="instructionNotes"
                  transferConditions="sous réserve de validation par le Département"
                  help={`Pour chacun des projets, saisissez le montant que vous souhaitez instruire. Les champs sont préremplis avec le montant demandé par le bénéficiaire. Vous pouvez baisser ou augmenter ce montant (dans la limite du montant disponible). Vous pouvez ajouter un commentaire en appuyant sur le bouton "loupe".`}
                  locked={payment.status !== "under-instruction"}
                  canCancel={false}
                />
              </Tabs.Tab>
            ) : null}
            {me.roles.includes("controller") ? (
              <Tabs.Tab id="controller" title="Validation niveau 1">
                <PaymentValidation
                  {...props}
                  type="controller"
                  amountProp={
                    payment.status === "under-level-1-validation"
                      ? "instructedAmount"
                      : "validatedAmount"
                  }
                  notesProps="validationNotes"
                  transferConditions="sous réserve de validation par le niveau 2"
                  help={`Pour chacun des projets, saisissez le montant que vous souhaitez valider. Les champs sont préremplis avec les montants suggérés par l\'instructeur. Vous pouvez baisser ou augmenter ce montant (dans la limite du montant disponible). Vous pouvez ajouter un commentaire en appuyant sur le bouton "loupe".`}
                  locked={payment.status !== "under-level-1-validation"}
                  canCancel
                />
              </Tabs.Tab>
            ) : null}
            {me.roles.includes("financier") ? (
              <Tabs.Tab id="financier" title="Validation niveau 2">
                <PaymentValidation
                  {...props}
                  type="financier"
                  amountProp={
                    payment.status === "under-level-2-validation"
                      ? "validatedAmount"
                      : "certifiedAmount"
                  }
                  notesProps="certificationNotes"
                  help={`Pour chacun des projets, saisissez le montant que vous souhaitez valider. Les champs sont préremplis avec les montants validés par le Département (niveau 1). Vous pouvez baisser ou augmenter ce montant (dans la limite du montant disponible). Vous pouvez ajouter un commentaire en appuyant sur le bouton "loupe".`}
                  locked={payment.status !== "under-level-2-validation"}
                  canCancel
                />
              </Tabs.Tab>
            ) : null}
          </Tabs>
        </Fragment>

        {me.roles.includes("instructor") ? (
          <PaymentSettings {...props} />
        ) : null}
      </Intersperse>
    </Page>
  );
}
