import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { sum } from "lodash";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import WithHelp from "../../RootNavigator/assistance/Help/WithHelp";
import AchievementTable from "./AchievementTable";
import { PaymentViewProps } from ".";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { InstructorPaymentValidation } from "@hpo/client/models/InstructorPayment";
import SideBar from "@hpo/client/components/SideBar";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { ListFrame, ListItem } from "@hpo/client/utilities/List";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import Button from "@hpo/client/components/Button";
import Divider from "@hpo/client/components/Divider";
import Dialog from "@hpo/client/components/Dialog";
import Buttons from "@hpo/client/components/Buttons";
import T from "@hpo/client/components/text/Text";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import PromiseToast from "@hpo/client/utilities/PromiseToast";
import MessageException from "@hpo/client/utilities/errors/MessageException";

type ValidationType = "instructor" | "controller" | "financier";

type PaymentValidationProps = PaymentViewProps & {
  type: ValidationType;
  amountProp:
    | "availableAmount"
    | "requestedAmount"
    | "instructedAmount"
    | "validatedAmount"
    | "certifiedAmount";
  notesProps: "instructionNotes" | "validationNotes" | "certificationNotes";
  transferConditions?: string;
  help?: string;
  locked: boolean;
  canCancel: boolean;
};

export default function PaymentValidation(props: PaymentValidationProps) {
  const {
    payment,
    type,
    onRefresh,
    amountProp,
    notesProps,
    transferConditions,
    help,
    locked,
    canCancel,
  } = props;

  const server = useServerSdk();

  const [values, setValues] = useState<Record<string, number | null>>({});

  useEffect(() => {
    setValues(() => {
      const output: Record<string, number | null> = {};
      payment.achievements.forEach((a) => {
        output[a.id] = a[amountProp];
      });
      return output;
    });
  }, [payment, amountProp]);

  const [notes, setNotes] = useState(() => {
    const output: Record<string, string | null> = {};
    payment.achievements.forEach((a) => {
      output[a.id] = a[notesProps];
    });
    return output;
  });

  const total = useMemo(() => {
    if (Object.values(values).includes(null)) return null;
    return sum(Object.values(values));
  }, [values]);

  const setValue = useCallback((a: string, amount: number | null) => {
    setValues((c) => ({ ...c, [a]: amount }));
  }, []);

  const setNote = useCallback((a: string, notes: string | null) => {
    setNotes((c) => ({ ...c, [a]: notes }));
  }, []);

  const [validate, confirmation] = useSubmitCallback(async () => {
    if (!payment.report) return;
    const confirmed = await Dialog.confirm({
      message: "Cette action est définitive",
      yesLabel: "Confirmer",
      noLabel: "Annuler pour vérifier",
    });
    if (!confirmed) return;
    const validation: InstructorPaymentValidation = {
      by: type,
      achievements: payment.achievements.map((a) => {
        const amount = values[a.id];
        if (amount === null) throw new Error("Missing amount");
        const note = notes[a.id];
        return { id: a.id, amount, notes: note };
      }),
      contentHash: payment.report.contentHash,
    };
    await server.validatePayment(payment.id, validation);
    onRefresh();
  }, [payment.id, onRefresh, values, notes, payment.report]);

  const [cancelSideBar, setCancelSideBar] = useState(false);
  const [cancelReason, setCancelReason] = useState<string | null>(null);

  const [goBackToInstruction, backingToInstruction] =
    useSubmitCallback(async () => {
      if (!cancelReason)
        throw new MessageException("Renseignez un motif de refus", null);
      await server.deletePaymentReport(payment.id, cancelReason);
      await onRefresh();
      setCancelSideBar(false);
      setCancelReason(null);
    }, [cancelReason, payment.id, onRefresh]);

  const [sidebar, setSidebar] = useState<string | null>(null);

  return (
    <Fragment>
      <WithHelp text={help} inactive={locked}>
        <ListFrame>
          {payment.achievements.map((a) => {
            return (
              <Fragment>
                <ListItem
                  label={`Pour le projet "${getProjectLabel(a.funding.project)}"`}
                  help={
                    a.availableAmount !== null
                      ? `${Units.euro.display(a.availableAmount)} maximum`
                      : undefined
                  }
                  right={
                    <Fragment>
                      <Button
                        icon="search"
                        onClick={() => setSidebar(a.id)}
                        style="discreet"
                      />
                      <Spacer horizontal />
                      <NumberField
                        value={values[a.id]}
                        onChange={(v) => setValue(a.id, v)}
                        unit="euro"
                        allowZero
                        max={a.availableAmount || undefined}
                        disabled={!payment.hasIban || locked}
                      />
                    </Fragment>
                  }
                />
                <SideBar
                  visible={sidebar === a.id}
                  onHide={() => setSidebar(null)}
                >
                  <TextField
                    textArea
                    label="Commentaire"
                    value={notes[a.id]}
                    onChange={(v) => setNote(a.id, v)}
                    help="Visible du bénéficiaire"
                    disabled={locked}
                  />
                  <Spacer />
                  <AchievementTable achievement={a} />
                </SideBar>
              </Fragment>
            );
          })}
          <Divider />
          <ListItem
            label="Total"
            right={<NumberField value={total} readonly unit="euro" />}
          />
          {payment.maximalAdvance !== null ? (
            <Fragment>
              <Divider />
              <ListItem
                label="Montant déjà versé avant la convention à déduire"
                right={
                  <NumberField
                    value={payment.maximalAdvance}
                    readonly
                    unit="euro"
                  />
                }
              />
              <Divider />
              <WithHelp
                title="Les versements effectués avant la convention sont gérés par la plateforme"
                text="Dans le montant que vous saisissez, vous ne devez pas déduire ce qui a déjà été versé avant la convention. Ce montant sera automatiquement déduit par la plateforme dès que le rapport d'instruction de demande de paiement sera signé par l'ensemble des parties."
              >
                <ListItem
                  label="Versement"
                  help={transferConditions}
                  right={
                    <NumberField
                      value={
                        total !== null
                          ? total > payment.maximalAdvance
                            ? total - payment.maximalAdvance
                            : 0
                          : null
                      }
                      readonly
                      allowZero
                      unit="euro"
                    />
                  }
                />
              </WithHelp>
              {total !== null && total < payment.maximalAdvance ? (
                <Fragment>
                  <Divider />
                  <ListItem
                    label="Montant déjà versé résiduel"
                    help="Reportable au prochain paiement"
                    right={
                      <NumberField
                        value={payment.maximalAdvance - total}
                        readonly
                        allowZero
                        unit="euro"
                      />
                    }
                  ></ListItem>
                </Fragment>
              ) : null}
            </Fragment>
          ) : null}
        </ListFrame>
      </WithHelp>

      <Spacer />
      {!locked ? (
        <WithHelp
          text={
            "Renseignez un IBAN (au niveau de la convention) pour modifier et valider ce paiement"
          }
          inactive={payment.hasIban}
          display="mouseover"
        >
          <WithHelp
            text={"Rédigez le rapport d'insctruction pour pouvoir signer"}
            inactive={!!payment.report}
            display="mouseover"
          >
            <Buttons expand>
              {canCancel ? (
                <Button
                  onClick={() => setCancelSideBar(true)}
                  label="Refuser le paiement en l'état"
                  style="danger"
                  disabled={!payment.hasIban || !payment.report}
                />
              ) : null}
              <Button
                onClick={validate}
                label="Signer"
                disabled={!payment.hasIban || !payment.report}
              />
            </Buttons>
          </WithHelp>
        </WithHelp>
      ) : null}
      <SideBar visible={cancelSideBar} onHide={() => setCancelSideBar(false)}>
        <T style="section" oneLine>
          Refuser le paiement
        </T>
        <Spacer />
        <T>
          Le paiement sera retourné au service d'instruction, qui pourra alors
          effectuer les modifications nécessaires.
        </T>
        <Spacer />
        <T>
          Indiquez le motif de votre refus. Ce motif sera uniquement transmis au
          service d'instruction.
        </T>
        <Spacer />
        <FieldsForm onSubmit={goBackToInstruction}>
          <TextField
            textArea
            label="Motif"
            value={cancelReason}
            onChange={setCancelReason}
            required
          />
          <Spacer />
          <Buttons expand>
            <Button
              label="Retour"
              onClick={() => setCancelSideBar(false)}
              style="discreet"
            />
            <Button submit label="Refuser le paiement en l'état" />
          </Buttons>
        </FieldsForm>
      </SideBar>
      <PromiseToast
        promise={backingToInstruction.promise}
        message="Refus enregistré"
      />
      <PromiseToast
        promise={confirmation.promise}
        message="Signature enregistrée"
      />
    </Fragment>
  );
}
