import {
  Infer,
  array,
  nullable,
  number,
  object,
  partial,
  string,
} from "superstruct";
import {
  FundingSummarySchema,
  InstructorFundingDetailsSchema,
} from "./Funding";
import {
  ReceiptCreationSchema,
  ReceiptSchema,
  ReceiptWithAnalysisSchema,
} from "./Receipt";
import { IndicatorSummarySchema } from "./Indicator";
import { FullfilledMarkerSchema, MarkerRequirementSchema } from "./Marker";

// GoalOrActualRequirement

const IndidcatorValueRequirementSchema = object({
  period: string(),
  value: nullable(number()),
});
export type IndidcatorValueRequirement = Infer<
  typeof IndidcatorValueRequirementSchema
>;

// BeneficiaryAchievement

export const BeneficiaryAchievementSchema = object({
  id: string(),
  grantedAmount: number(),
  stockAmount: number(),
  availableAmount: number(),
  requestedAmount: nullable(number()),
  requestNotes: nullable(string()),
  funding: FundingSummarySchema,
  receipts: array(ReceiptSchema),
  indicators: array(IndicatorSummarySchema),
  markers: array(MarkerRequirementSchema),
});

export type BeneficiaryAchievement = Infer<typeof BeneficiaryAchievementSchema>;

// BeneficiaryAchievementEdition

export const BeneficiaryAchievementEditionSchema = object({
  id: string(),
  receipts: array(ReceiptCreationSchema),
  markers: array(FullfilledMarkerSchema),
  amount: number(),
  notes: nullable(string()),
});

export type BeneficiaryAchievementEdition = Infer<
  typeof BeneficiaryAchievementEditionSchema
>;

// InstructorAchievement

export const InstructorAchievementSchema = object({
  id: string(),
  funding: InstructorFundingDetailsSchema,
  receipts: array(ReceiptWithAnalysisSchema),
  grantedAmount: number(),
  stockAmount: nullable(number()),
  availableAmount: nullable(number()),
  requestedAmount: nullable(number()),
  requestNotes: nullable(string()),
  indicatorNotes: nullable(string()),
  instructedAmount: nullable(number()),
  instructionNotes: nullable(string()),
  validatedAmount: nullable(number()),
  validationNotes: nullable(string()),
  certifiedAmount: nullable(number()),
  certificationNotes: nullable(string()),
  stockableAmount: nullable(number()),
});

export type InstructorAchievement = Infer<typeof InstructorAchievementSchema>;

// InstructorAchievementEdition

export const InstructorAchievementEditionSchema = partial(
  object({
    grantedAmount: number(),
    indicatorNotes: nullable(string()),
  }),
);

export type InstructorAchievementEdition = Infer<
  typeof InstructorAchievementEditionSchema
>;
