import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import dayjs, { extend, locale } from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import fr from "dayjs/locale/fr";
import IndicatorType, { IndicatorTypeLabels } from "../enums/IndicatorType";
import Units from "../Units";
import { Signature } from "@hpo/client/models/Signature";
import { Analysis } from "@hpo/client/models/Analysis";
import { InstructorIndicator } from "@hpo/client/models/Indicator";

extend(localizedFormat);
locale(fr);

type PropertiesProps = {
  props: Array<[string, ReactNode] | null>;
};

function Properties(props: PropertiesProps) {
  return (
    <table>
      <tbody>
        {props.props.map((p, i) =>
          p ? (
            <tr key={i}>
              <th>{p[0]}</th>
              <td>{p[1]}</td>
            </tr>
          ) : null,
        )}
      </tbody>
    </table>
  );
}

function Columns(props: PropsWithChildren) {
  return <div className="columns">{props.children}</div>;
}

function Space(props: { size: number }) {
  return <div style={{ height: `${props.size}cm` }} />;
}

type WithLogoProps = PropsWithChildren<{
  logo: string;
}>;

function WithLogo(props: WithLogoProps) {
  return (
    <section className="with-logo">
      <div className="content">{props.children}</div>
      <div className="logo">
        <img src={props.logo} />
      </div>
    </section>
  );
}

function PageBreak() {
  return <div className="page-break" />;
}

type SignatureZoneProps = {
  label: string;
  signature: Signature | null;
};

function SignatureZone(props: SignatureZoneProps) {
  const { label, signature } = props;
  let content: ReactNode = <Fragment />;
  if (signature) {
    content = (
      <Fragment>
        <div>
          Signé par {signature.author} <br />
          le {dayjs(signature.date).format("LL")}
        </div>
        <div>
          <small>
            <br />
            {signature.contentHash} / {signature.id}
          </small>
        </div>
      </Fragment>
    );
  }
  return (
    <section>
      <div className="signature">
        {label}
        <div className="frame">{content}</div>
      </div>
    </section>
  );
}

type BadgeProps = {
  type?: "success" | "error";
  children: string;
};

function Badge(props: BadgeProps) {
  return <span className={`badge ${props.type}`}>{props.children}</span>;
}

type AnalysisBadgeProps = {
  analysis: Analysis;
};

function AnalysisBadge(props: AnalysisBadgeProps) {
  const { analysis } = props;
  if (analysis.skippable) return <Badge>Non attendu</Badge>;
  else {
    if (analysis.decision == true)
      return <Badge type="success">Conforme</Badge>;
    else if (analysis.decision == false)
      return <Badge type="error">Non conforme</Badge>;
    else return <Badge>A déterminer</Badge>;
  }
}

type AnalysisDetailProps = {
  analysis: Analysis;
};

function AnalysisDetail(props: AnalysisDetailProps) {
  return (
    <div className="analysis">
      <div className="badge-zone">
        <AnalysisBadge analysis={props.analysis} />
      </div>
      <div className="comment-zone">{props.analysis.comment}</div>
    </div>
  );
}

type IndicatorsTableProps = {
  hideTypes?: boolean;
  periods: Array<string>;
  indicators: Array<InstructorIndicator>;
  withAnalisys?: boolean;
};

function IndicatorsTable(props: IndicatorsTableProps) {
  const {
    hideTypes = false,
    periods,
    indicators,
    withAnalisys = false,
  } = props;

  const types = [
    IndicatorType.Achievement,
    IndicatorType.Result,
    IndicatorType.Communication,
    IndicatorType.Transfert,
  ];

  if (indicators.length === 0)
    return (
      <tbody>
        <tr>
          <td>Aucun indicateur</td>
        </tr>
      </tbody>
    );

  return (
    <Fragment>
      {hideTypes ? (
        <thead>
          <th></th>
          {periods.map((p) => (
            <th key={p}>{p}</th>
          ))}
          {withAnalisys ? (
            <Fragment>
              <th>Conformité</th>
              <th>Commentaire de l'instructeur</th>
            </Fragment>
          ) : null}
        </thead>
      ) : null}
      {types.map((type) => {
        const filtered = indicators.filter((i) => i.type === type);
        if (filtered.length === 0) return null;
        return (
          <Fragment>
            {hideTypes ? null : (
              <thead>
                <th>{IndicatorTypeLabels[type]}</th>
                {periods.map((p) => (
                  <th key={p}>{p}</th>
                ))}
                {withAnalisys ? (
                  <Fragment>
                    <th>Conformité</th>
                    <th>Commentaire de l'instructeur</th>
                  </Fragment>
                ) : null}
              </thead>
            )}
            <tbody>
              {filtered.map((i: InstructorIndicator) => {
                return (
                  <tr key={i.id}>
                    <td>{i.label}</td>
                    {periods.map((p) => {
                      const goal = i.goals.find((i) => i.period === p);
                      const actual = i.actuals.find((i) => i.period === p);
                      let content: ReactNode = null;
                      if (goal && actual)
                        content = (
                          <Fragment>
                            {Units[i.unit].display(actual.value)}
                            <small>
                              {" "}
                              / {Units[i.unit].display(goal.value)}
                            </small>
                          </Fragment>
                        );
                      else if (goal)
                        content = `${Units[i.unit].display(goal.value)}`;
                      return <td key={p}>{content}</td>;
                    })}
                    {withAnalisys ? (
                      <Fragment>
                        <td>
                          {"analysis" in i ? (
                            <AnalysisBadge analysis={i.analysis} />
                          ) : null}
                        </td>
                        <td>{"analysis" in i ? i.analysis.comment : null}</td>
                      </Fragment>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

function Footer(props: { appUrl: string }) {
  return (
    <Fragment>
      <Print.Space size={3} />
      <hr />
      <Print.WithLogo logo={"/logo-3a.png"}>
        <small>
          <p>AMO Assistant à Maitrise d’Ouvrage du Département de La Réunion</p>
          <p>Attributaire d’un marché public</p>
          <br />
          <p>3A CONSEIL</p>
          <p>
            <a href={"https://www.3aoverseas.com"} target="_blank">
              https//www.3aoverseas.com
            </a>{" "}
            -{" "}
            <a href={"tel:+262262666968"} target="_blank">
              +262(0)262 66 69 68
            </a>{" "}
            -{" "}
            <a href={"mailto:hpo@3areunion.com"} target="_blank">
              hpo@3areunion.com
            </a>
          </p>
          <p></p>
        </small>
      </Print.WithLogo>
      <hr />
      <Print.Space size={1} />
      <small>
        <p className="center">
          Document réalisé via la platfeforme HPO disponible sur{" "}
          <a href={props.appUrl} target="_blank">
            {props.appUrl}
          </a>
        </p>
      </small>
    </Fragment>
  );
}

const Print = {
  SignatureZone,
  PageBreak,
  WithLogo,
  Space,
  Columns,
  Properties,
  Badge,
  AnalysisBadge,
  IndicatorsTable,
  AnalysisDetail,
  Footer,
};
export default Print;
